import {useState, SyntheticEvent } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { api_user_getUser, api_user_login } from '../api/user';
import { IUser } from '../types/user';

const Login = (props:{user:IUser|null, updateUser: (setUser:any)=>void }) => {
    const[ email, setEmail ] = useState("");
    const[ password, setPassword ] = useState("");
    const[ msg, setMsg] = useState('');
    let message;

    // on submit ('log in'), post the username and password to the login api,
    const submit = async (e:SyntheticEvent) =>{
        e.preventDefault();   // prevents page refresh (default for submit)
        const content = await api_user_login( email, password );   // login user with API call

        if(!content.ok){
            setMsg(content.message);
            props.updateUser(null);
        }
        else {
            setMsg("");
            const content = await api_user_getUser();
            //console.log(content);
            props.updateUser(content);
        }
    }

    // Display error message if login fails
    if( msg !== '' ){
        message = (
            <div className="alert alert-danger" role="alert">
                {msg}. Trouble logging in? <a href="mailto:support@freyavivariums.com">Contact support</a>.
            </div>
        );
    }
    
    // if a user is logged in, redirect to home
    const navigate = useNavigate();
    if( props.user !== null ){
      navigate('/');
    }

    return(
        <main className="form-signin" style={{backgroundColor:'var(--freya_white)', borderRadius:'10px', color:'var(--freya_black)', marginTop:'7vh'}}>
            <form onSubmit={submit}>
            <h1 className="h3 mb-3 fw-normal">Sign in</h1>

                <div className="input-group mb-3">
                    <input type="email" className="form-control" aria-describedby="basic-addon1" placeholder="E-mail" required onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="input-group mb-3">
                    <input type="password" className="form-control" placeholder="Password" required onChange={e => setPassword(e.target.value)}/>
                </div>
                {message}
                <button className="w-100 btn btn-lg btn-primary" type="submit">Sign in</button>
                <a href='#' onClick={()=>{window.alert("No worries!\n\nSend an e-mail to support@freyavivariums.com to recover your account.")}}>forgot your password?</a>
                <br/>
                <hr/>
                <p>Don't have an account yet? Click here to <Link to='/register'>register</Link>!</p>
            </form>
        </main>
    )
}

export default Login;