import './LoadingOverlay.css';

const LoaderOverlay = ( props:{ text?:string, subtext?:string, hidden?:boolean, spinner?:boolean}) => {
if(!props.hidden){
  return(
            <div className="loaderOverlay">
              <div className="lds-ring" hidden={props.spinner?false:true}><div></div><div></div><div></div><div></div></div>
              <div style={{marginLeft:"10px"}}>
                <h1>{props.text?props.text:''}</h1>
                <p>{props.subtext?props.subtext:''}</p>
              </div>
            </div>
    )
  }
  else{
    return(<></>);
  }
}

export default LoaderOverlay;