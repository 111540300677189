import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";



const TermsAndConditions = () => {

  return(<>
    <h2>Terms & Conditions</h2>
    <p>A legally binding contract between Biosphere and the users on how they are going to be using the Biosphere web application.</p>

    <Alert variant='info'><FontAwesomeIcon icon={faInfoCircle}/> <strong>Biosphere is still in development. </strong> We're working
    on the terms and conditions. But I can assure you, Biosphere is designed with the user's best interests in mind. I'm also expecting a
    proper usage of Biosphere from my users, and for now I give myself the right to delete any misbehaving user (after a warning).</Alert>
    
    <h3>General</h3>
    <p>Don't be evil. Not you, not me.</p>

    <h3>Home Project</h3>
    <p>
      Biosphere is currently a homebrew project of mine. I do my very best to keep it secure and online. Though, I'm using minimal 
      infrastructure. There's always te possibility of data loss or server downtime. Again, I'm doing my best to prevent that from happening.
    </p>

    <h3>Free Account</h3>
    <p>
      Biosphere is free to use. If you love Biosphere, give us your support by funding this project.
    </p>

  </>);
}

export default TermsAndConditions;