import { faUser, faAt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SyntheticEvent, useEffect, useState } from "react";
import { Form, InputGroup } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { api_user_update_info } from '../../api/user';
import { IUser } from '../../types/user';

const UpdateUser = ( props:{ user:IUser|null, onChange?:Function|null } )=>{
    // New Password
    const[ name, setName ] = useState("");
    const[ email, setEmail ] = useState("");
    const[ about, setAbout ] = useState("");

    const[ msg, setMsg ] = useState('');
    const[ msgType, setMsgType ] = useState('success');
    let message;
    
    useEffect(() => {
        if(!props.user)return;
        setName(props.user.name);
        setEmail(props.user.email);
        setAbout(props.user.about);
      }, []);

    const submit = async (e:SyntheticEvent) =>{
        e.preventDefault();   // prevents page refresh

        if(name.length > 100){
            setMsgType('error');
            setMsg('Your name is too long for our database!');
            return;
        }
        if(email.length > 100 ){
            setMsgType('error');
            setMsg('Your E-mail is too long for our database!');
            return;
        }
        if(about.length > 150 ){
            setMsgType('error');
            setMsg('Your about is too long for our database!');
            return;
        }
    
        const content = await api_user_update_info( name, email, about );   // API call for updating password
        if(content.message !== 'success'){
            setMsgType('error');
            setMsg(content.message);
        }
        else{
            setMsgType('success');
            setMsg('Profile successfuly updated!');
            if(typeof props.onChange === 'function') props.onChange();
        }
        
    }

    // Display message
    if( msg !== '' ){
        if( msgType !== 'success'){
            message = (
                <div className="alert alert-danger">
                    {msg}
                </div>
            );
        }
        else{
            message = (
                <div className="alert alert-success">
                    {msg}
                </div>
            );
        }
    }
      
    if(props.user !== null){
        return(
            <form onSubmit={submit}>
                <h3><FontAwesomeIcon icon={faUser}/> Profile</h3>
                <p>Update your user info.</p>
                <InputGroup className='mb-3'>
                    <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                    <Form.Control type={'text'} required value={name} placeholder="Your name" onChange={e => setName(e.target.value)}/>
                </InputGroup>
                <InputGroup className='mb-3'>
                    <InputGroup.Text><FontAwesomeIcon icon={faAt} /></InputGroup.Text>
                    <Form.Control type={'email'} required value={email} placeholder="E-mail" onChange={e => setEmail(e.target.value)}/>
                </InputGroup>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>About</Form.Label>
                    <Form.Control as="textarea" maxLength={150} rows={3} value={about} placeholder={'Some lines about yourself.'} onChange={e => setAbout(e.target.value)}/>
                </Form.Group>
                {message}
                <button className="w-100 btn btn-lg btn-primary" type="submit">Update profile</button>
            </form>
        );
    }
    else{
        return <Navigate to="/" />;
    }
}

export default UpdateUser;
