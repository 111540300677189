import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { api_user_logout } from '../api/user';
import { IUser } from '../types/user';

const Logout = (props:{user:IUser|null, updateUser: (setUser:any)=>void }) => {
    useEffect(()=>{
        api_user_logout().then(()=>{props.updateUser(null)});
    },[]);

    return <Navigate to="/" />;
}

export default Logout;