import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Modal } from 'react-bootstrap';
import { api_user_saveImage } from '../../api/user';
import LoaderOverlay from '../LoadingOverlay';
import { IUser } from '../../types/user';

const UserImageModal = forwardRef( (props:{ user:IUser|null, onChange?:Function|null }, ref ) => {
    // Function for parent to open/close the modal
    useImperativeHandle(ref, () => ({
        showModal: ( state:boolean ) => setShow(state)
    }));

    // Modal open/close state and functions
    const [show, setShow] = useState(false);

    const [ saving, isSaving ] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [preview, setPreview] = useState<string>();

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            if(!props.user) return;
            setPreview('profilepics/'+props.user.picture);
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    function onImageChange(e:React.ChangeEvent<HTMLInputElement>){
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(null);
            return
        }
        // check file size
        if(e.target.files[0].size > 3000000){
            setSelectedFile(null);
            console.log("file size to large!");
            return
        }
        // single file
        setSelectedFile(e.target.files[0]);
    }

    async function saveImage(){
        isSaving(true);
        console.log(selectedFile);
        try{
            if(selectedFile){
                const result = await api_user_saveImage( selectedFile );
                if (result.message === 'success'){
                    // Trigger a change event
                    if(typeof props.onChange === 'function') props.onChange();
                    // Close the modal
                    setShow(false);
                }
            }
        }catch(e){}
        isSaving(false);
    }

    /* For the hidden File input */
    const inputRef = useRef<HTMLInputElement>(null);
    function chooseFileHandler(){
        inputRef.current?.click();
    }

    // Conditionally render the save-button
    function SaveButton(){
        return <Button
                    style={{width:"100%"}}
                    onClick={()=>saveImage()}
                    disabled={saving?true:false}
                >{saving?'Saving...':'Save'}</Button>
    }

    return(
        <>
            <Modal show={show} onHide={()=>{setShow(false)}}>
                <LoaderOverlay hidden={!saving} spinner text={"Saving..."} subtext={"This may take a little while"} />
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faCamera} /> Profile picture</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <input ref={inputRef} className='d-none' type="file" accept="image/png, image/jpg, image/jpeg" disabled={saving?true:false} onChange={onImageChange}/>
                        
                        {props.user && props.user.picture && !preview?
                            <img alt="profile" src={'/profilepics/'+props.user.picture} width="100%"
                                onClick={()=>{chooseFileHandler()}}
                                onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="./NoUser_512.png";
                            }} />
                        :
                            <img alt="profile" src={preview} width="100%"
                                onClick={()=>{chooseFileHandler()}}
                                onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src="./NoUser_512.png";
                            }}
                            />
                        }
                    </Form>
                    <hr/>
                    <SaveButton />
                </Modal.Body>
            </Modal>
        </>
    )
});

export default UserImageModal;