import {  useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import UpdatePassword from '../components/user/UpdatePassword';
import UpdateUser from '../components/user/UpdateUser';
import UserImageModal from '../components/user/UserImageModal';
import { api_user_delete } from '../api/user';
import LoaderOverlay from '../components/LoadingOverlay';
import { IUser } from '../types/user';

const User = ( props:{user:IUser|null, onChange:Function|null } )=>{
  // Loading Overlay
  const[ loading, setLoading] = useState(false);
  const[ loadingText, setLoadingText ] = useState('');
  const[ loadingSubtext, setLoadingSubtext ] = useState('');
  // Navigate
  const navigate = useNavigate();
  // 'Add Image' modal open/close
  const userImageModal = useRef<{showModal:Function}>();

  // if not logged in, you have no business here!
  if(props.user === null){
    return <Navigate to="/login" />;
  }

  // Delete this user
  async function deleteUser(){
    let confirmDelete = window.confirm("Are you sure you want to delete your Biosphere account and everything you've created?");
    if(confirmDelete){
      setLoadingText('Deleting user');
      setLoading(true);
      const result = await api_user_delete();
      if( result.message !== 'success' ){
        setLoading(false);
        console.log(result.message);
        return;
      }
      setLoadingText('Success!');
      setTimeout(()=>{
        setLoading(false);
        navigate('/logout');
      },700);
    }
  }


  return(
    <>
      <LoaderOverlay spinner hidden={!loading} text={loadingText} subtext={loadingSubtext}/>
      <Container>
        <UserImageModal ref={userImageModal} user={props.user} onChange={props.onChange}/>
        <main className="form-signin" style={{textAlign: 'center'}}>
            <img alt="profilepicture" src={window.location.origin+'/profilepics/'+props.user.picture+"?t="+ new Date().getTime()} width="50%"
              onClick={e =>{ userImageModal.current?.showModal(true)}}
              style={{borderRadius:"10%"}}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src=process.env.PUBLIC_URL+"/NoUser_512.png";
                }}
            />
            <h3>{props.user?props.user.name:<></>}</h3>
            <p>@{props.user?props.user.username:<></>}</p>
          </main>
        <hr/>
        <UpdateUser user={props.user} onChange={props.onChange}/>
        <hr/>
        <UpdatePassword user={props.user} />
        <hr/>
        <h3><FontAwesomeIcon icon={faTrash} /> Delete Account</h3>
        <Button variant='danger' onClick={()=>deleteUser()}><FontAwesomeIcon icon={faTrash} /> Delete</Button>
        <br/>
        <br/>
      </Container>
    </>
  )
}

export default User;