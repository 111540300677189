import { Col, Container, Row } from 'react-bootstrap';
import { IUser } from '../types/user';
import BiospherePromo from './BiospherePromo';

const Home = ( props:{user:IUser|null} ) => {
  return (<>
    <Row >
      <Col md={6} sm={12} style={{padding:'6vw', textAlign: 'center'}}>
        <br />
        <img alt="logo" src={process.env.PUBLIC_URL+'/logo512.png'} width="40%" />
        <br />
        <br />
        <h1>FREYA VIVARIUMS™</h1>
      </Col>
      <Col md={6} sm={12} style={{padding:'6vw'}}>
        <div style={{backgroundColor:'var(--freya_dark_green)', fontSize:'1.3rem', padding:'2vw', borderRadius:'10px'}}>
        <h3>Bring the beauty and wonder of nature in your home</h3>
        <p style={{textAlign:'justify'}}>
          Transform your living space into a lush, vibrant jungle with our beautiful vivariums. 
          Whether you're a plant enthusiast or simply looking to add some life and color to your home or office, 
          our vivariums offer a stunning and low-maintenance solution. With customizable features and easy-to-use technology,
          you'll be able to create a unique and thriving ecosystem that brings joy and relaxation to your everyday life. 
          Order now and experience the beauty of nature right in your own home!
        </p>
        </div>
      </Col>
    </Row>
    {/*<BiospherePromo/>*/}
  </>);
}

export default Home;