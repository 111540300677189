import { SyntheticEvent, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { api_user_register } from '../api/user';
import LegalModal from '../components/legal/LegalModal';
import { IUser } from '../types/user';

const Register = ( props:{user:IUser|null}) => {
  const[ name, setName ] = useState("");
  const[ email, setEmail ] = useState("");
  const[ username, setUsername ] = useState("");
  const[ password, setPassword ] = useState("");
  const[ reenterPassword, setReenterPassword ] = useState("");
  const[ redirect, setRedirect ] = useState(false);
  const[ msg, setMsg ] = useState('');
  const[ acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const[ acceptPrivacyPolicy, setAcceptPrivacyPolicy ] = useState(false);
  let message;


  // 'Add Image' modal open/close
  const legalModal = useRef<{showModal:Function}>();

  const submit = async (e:SyntheticEvent) =>{
    e.preventDefault();   // prevents page refresh

    // Check Privacy Policy
    if(!acceptPrivacyPolicy){
      setMsg("You must accept the privacy policy to continue");
      return;
    }
    // Check Terms & Conditions
    if(!acceptTermsAndConditions){
      setMsg("You must accept the terms and conditions to continue");
      return;
    }
    // Username must be at least 3 characters
    if( username.length <=3 ){
      setMsg("Your username must have at least 3 characters");
      return;
    }
    // Username may only contain alphanumeric characters
    const specialChars = /[`!@# $%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if( specialChars.test(username) ){
      setMsg("Your username may only contain alphanumeric characters");
      return;
    }

    if(reenterPassword !== password ){    // prevent typo's in the password
      setMsg("Entered passwords not the same!");
      return;
    }

    else{
      const content = await api_user_register( name, email, username, password); // Register with API call

      if(content.message !== 'success'){
          setMsg(content.message);
      }
      else {
          setRedirect(true);
      }
    }
  }

  // Display error message if registration fails
  if( msg !== '' ){
    message = (
      <div className="alert alert-danger" role="alert">
        {msg}. Trouble registering? <a href="mailto:support@freyavivariums.com">Contact support</a>.
      </div>
    );
  }

  const navigate = useNavigate();
  if( redirect ){
    setTimeout(()=>{navigate('/login')},7000);
    return<main className='form-signin'>
            <h3>An e-mail is sent to {email}</h3>
            <p>Check your inbox and click the activation link to activate your Biosphere account!</p>
          </main>;
  }


  return(
    <>
      <LegalModal ref={legalModal} />
      <main className="form-signin" style={{backgroundColor:'var(--freya_white)', borderRadius:'10px', color:'var(--freya_black)', marginTop:'7vh'}}>
        <form onSubmit={submit}>
          <h1 className="h3 mb-3 fw-normal">Register</h1>
          <div className="input-group mb-3">
            <input type="name" className="form-control" placeholder="Name" required onChange={e => setName(e.target.value)}/>
          </div>
          <div className="input-group mb-3">
            <input type="name" className="form-control" placeholder="Username" required onChange={e => setUsername(e.target.value)} />
          </div>
          <div className="input-group mb-3">
            <input type="email" className="form-control" placeholder="E-mail" required onChange={e => setEmail(e.target.value)}/>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Password" required onChange={e => setPassword(e.target.value)}/>
          </div>
          <div className="input-group mb-3">
            <input type="password" className="form-control" placeholder="Re-enter password" /*required*/ onChange={e => setReenterPassword(e.target.value)}/>
          </div>
          <Form.Check type='checkbox' id='terms&conditions' checked={acceptTermsAndConditions} onChange={(e)=>{setAcceptTermsAndConditions(e.target.checked)}} label={<>Accept <a href='#' onClick={()=>legalModal.current?.showModal(true)}>Terms & Conditons</a></>} />
          <Form.Check type='checkbox' id='privacyPolicy' checked={acceptPrivacyPolicy} onChange={(e)=>{setAcceptPrivacyPolicy(e.target.checked)}} label={<>Accept <a href='#' onClick={()=>legalModal.current?.showModal(true)}>Privacy policy</a></>} />
          <br/>
          {message}
          <button className="w-100 btn btn-lg btn-primary" type="submit">Register</button>
        </form>
      </main>
    </>
  )
}

export default Register;