/*
 *  Biosphere App
 *
 *  Copyright 2022 Sanne 'SpuQ' Santens. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Nav from './components/nav';
import Home from './pages/Home';
import Login from './pages/login';
import Logout from './pages/logout';
import Register from './pages/register';
import User from './pages/user';
import NotFound from './pages/NotFound';
import { api_user_getUser } from './api/user';
import { Container } from 'react-bootstrap';
import CheckStandAlone from './components/CheckStandAlone';
import ActivateAccount from './pages/ActivateAccount';
import { IUser } from './types/user';
import LoaderOverlay from './components/LoadingOverlay';
import Legal from './pages/Legal';

function App() {
  const[ appUser, setAppUser ] = useState<null|IUser>( null );

  useEffect(() => {
    updateAppUser();
  }, []);

  async function updateAppUser(){
    const content = await api_user_getUser();   // API call for getting User
    if(!content.message){
      setAppUser(content);
    }
    else{
      setAppUser(null);
    }
  }

  //  Loading overlay
  const[ loadingText, setLoadingText ] = useState('');
  const[ loadingSubText, setLoadingSubText ] = useState('');
  const[ loading, setLoading ] = useState(false);
  function isLoading( loading?:boolean, loadingText?:string, loadingSubText?:string){
    if( loading ){
      setLoadingText( loadingText?loadingText:'' );
      setLoadingSubText( loadingSubText?loadingSubText:'');
      setLoading( true );
    }
    else{
      setLoading( false );
      setLoadingText('');
      setLoadingSubText('');
    }
  }

  return(
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <LoaderOverlay text={loadingText} subtext={loadingSubText} spinner hidden={!loading}/>
        <Nav user={appUser} onChange={updateAppUser}/>
        <Container className='mainContainer'>
          <Routes>
            <Route path='*' element={<NotFound user={appUser} />} />
            <Route path="/" element={<Home user={appUser}/>}/>

            <Route path="/activate/:email/:code">
              <Route index element={<ActivateAccount user={appUser} onChange={updateAppUser}/>} />
            </Route>

            <Route path="/login" element={<Login user={appUser} updateUser={updateAppUser} />}/>
            <Route path="/logout" element={<Logout user={appUser} updateUser={updateAppUser} />}/>
            <Route path="/register" element={<Register user={appUser} />}/>
            <Route path="/user" element={<User user={appUser} onChange={updateAppUser} />}/>
            <Route path="/legal" element={<Legal user={appUser} />}/>
          </Routes>
        </Container>
      </BrowserRouter>
      <CheckStandAlone />
    </div>
  );
}

export default App;
