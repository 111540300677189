import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SyntheticEvent, useState } from "react";
import { Navigate } from 'react-router-dom';
import { api_user_update_password } from "../../api/user";
import { IUser } from '../../types/user';

const UpdatePassword = ( props:{user:IUser|null} )=>{
    // New Password
    const[ password, setPassword ] = useState("");
    const[ newPassword, setNewPassword ] = useState("");
    const[ reenterPassword, setReenterPassword ] = useState("");

    const[ msg, setMsg ] = useState('');
    const[ msgType, setMsgType ] = useState('success');
    let message;
    
    const submit = async (e:SyntheticEvent) =>{
        e.preventDefault();   // prevents page refresh
    
        if(reenterPassword !== newPassword ){    // prevent typo's in the password
            setMsgType('error');
            setMsg("Entered new passwords not the same!");
            return;
        }

        if(newPassword.length < 8){
            setMsgType('error');
            setMsg("New password is too short!");
            return;
        }
        else{
            const content = await api_user_update_password( password, newPassword );   // API call for updating password
    
            if(content.message !== 'success'){
                setMsgType('error');
                setMsg(content.message);
            }
            else{
                setPassword('');
                setNewPassword('');
                setReenterPassword('');
                setMsgType('success');
                setMsg('Password successfuly updated!');
            }
        }
    }

    // Display message
    if( msg !== '' ){
        if( msgType !== 'success'){
            message = (
                <div className="alert alert-danger">
                    {msg}
                </div>
            );
        }
        else{
            message = (
                <div className="alert alert-success">
                    {msg}
                </div>
            );
        }
    }
      
    if(props.user !== null){
        return(
            <form onSubmit={submit}>
                <h3><FontAwesomeIcon icon={faKey}/> Password</h3>
                <p>Set a new password for this account.</p>
                <div className="input-group mb-3">
                    <input type="password" className="form-control" value={password} placeholder="Old password" required onChange={e => setPassword(e.target.value)}/>
                </div>
                <div className="input-group mb-3">
                    <input type="password" className="form-control"  value={newPassword} placeholder="New password" required onChange={e => setNewPassword(e.target.value)}/>
                </div>
                <div className="input-group mb-3">
                    <input type="password" className="form-control"  value={reenterPassword} placeholder="Re-enter password" required onChange={e => setReenterPassword(e.target.value)}/>
                </div>
                {message}
                <button className="w-100 btn btn-lg btn-primary" type="submit">Change password</button>
            </form>
        );
    }
    else{
        return <Navigate to="/" />;
    }
}

export default UpdatePassword;
