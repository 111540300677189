/*
 *  Biosphere REST API - USER
 *  All user stuff.
 *  
 *  Copyright 2022 Sanne 'SpuQ' Santens. All rights reserved.
 */

// Login
// Log in the user with e-mail and password
// On success, the user data will be returned with a jwt cookie in the
// header.
export async function api_user_login( email:string, password:string ){
    const response = await fetch(window.location.origin+"/api/user/login", {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                email: email,
                password: password
        })
    });
    var content = await response.json();
    content.ok = response.ok;
    return content;
}

// Logout
// Log out the current user by destorying the jwt cookie.
export async function api_user_logout(){
    const response = await fetch(window.location.origin+"/api/user/logout", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
    });
    const content = await response.json();
    return content;
}

// Register
// Register a new user with name, e-mail and password
export async function api_user_register( name:string, email:string, username:string, password:string){
    const response = await fetch(window.location.origin+"/api/user/register", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            name: name,
            email: email,
            username: username,
            password: password
        })
    });
    const content = await response.json();
    return content;
}

// ACTIVATE
// Activate a new user with e-mail and activation code
export async function api_user_activate( email:string, code:string ){
    const response = await fetch(window.location.origin+"/api/user/activate", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            email: email,
            code: code
        })
    });
    const content = await response.json();
    return content;
}

//  Delete User
//  Delete the current logged in user
export async function api_user_delete(){
    const response = await fetch(window.location.origin+"/api/user/user", {
        method: "DELETE",
        headers: {'Content-Type': 'application/json'},
    });
    const content = await response.json();
    return content;
}

// Get User info
// Get all the user info
export async function api_user_getUser(){
    const response = await fetch(window.location.origin+"/api/user/user", {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
      });
      
    const content = await response.json();
    return content;
}

// Get User Profile
// Get a user's profile by username
// Username is passed as URL parameter
export async function api_user_getProfile( username:string ){
    const response = await fetch(window.location.origin+"/api/user/profile?username="+username, {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
      });
      
    const content = await response.json();
    return content;
}

// Change Password
// Set a new password, by providing the old and the new password
export async function api_user_update_password( oldPassword:string, newPassword:string ){
    const response = await fetch(window.location.origin+"/api/user/updatePassword", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({
            oldPassword: oldPassword,
            newPassword: newPassword
        })
    });
    const content = await response.json();
    return content;
}

// Change Info
// Update the user info (name, e-mail, about)
export async function api_user_update_info( name:string, email:string, about:string ){
    const response = await fetch(window.location.origin+"/api/user/updateInfo", {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include',
        body: JSON.stringify({
            name: name,
            email: email,
            about: about
        })
    });
    const content = await response.json();
    return content;
}

// Follow
export async function api_user_follow( username:string ){
    const response = await fetch(window.location.origin+"/api/user/follow", {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                username: username
        })
    });

    var content = await response.json();
    content.ok = response.ok;
    return content;
}

// Unfollow
export async function api_user_unfollow( username:string ){
    const response = await fetch(window.location.origin+"/api/user/unfollow", {
            method: "POST",
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify({
                username: username
        })
    });

    var content = await response.json();
    content.ok = response.ok;
    return content;
}

// Get followed Biospheres
// No authentication required!
export async function api_user_getFollowedBiospheres( username?:string ){
    const response = await fetch(window.location.origin+"/api/user/followedBiospheres?username="+username, {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
      });
    const content = await response.json();
    return content;
}

// Get User Profile
// Get a user's profile by username
// Username is passed as URL parameter
export async function api_user_getFollowers( username?:string ){
    var params = ''
    if( typeof username !== 'undefined') params = "?username="+username;
    const response = await fetch(window.location.origin+"/api/user/followers"+params, {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
        credentials: 'include'
      });
      
    const content = await response.json();
    return content;
}

// Set User Image
// Set an image for the logged-in user
// returns error or success
export async function api_user_saveImage( picture:File ){
    let formData = new FormData();
    formData.append( 'picture', picture );
    const response = await fetch(window.location.origin+"/api/user/picture", {
        method: "POST",                 // (!) browser will detect automatically the Content-Type
        credentials: 'include',
        body: formData
    });

    const content = await response.json();
    return content;
}

// DELETE all User's NOTIFICATIONS
// Deletes all the notifications from this user
// returns error or success message
export async function api_user_deleteAllNotifications(){
    const response = await fetch(window.location.origin+"/api/user/notifications", {
        method: "DELETE",
        headers: {'Content-Type': 'application/json'},
    });
    const content = await response.json();
    return content;
}

// GET all User's NOTIFICATIONS
// Get all the notifications from this user
// returns an array
export async function api_user_getNotifications(){
    const response = await fetch(window.location.origin+"/api/user/notifications", {
        method: "GET",
        headers: {'Content-Type': 'application/json'},
    });
    const content = await response.json();
    return content;
}