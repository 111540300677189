import { forwardRef, useImperativeHandle, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faContactCard, faGavel, faLock, faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

const LegalModal = forwardRef( (props:{}, ref ) => {
    // Function for parent to open/close the modal
    useImperativeHandle(ref, () => ({
        showModal: ( state:boolean ) => setShow(state)
    }));
    // Modal open/close state and functions
    const [show, setShow] = useState(false);

    return(
        <>
            <Modal size={'lg'} show={show} onHide={()=>{setShow(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title><FontAwesomeIcon icon={faScaleBalanced} /> Legal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className={'mb-3'} defaultActiveKey={'terms&conditions'} id={'legalTabs'}>
                        <Tab eventKey="terms&conditions" title={<><FontAwesomeIcon icon={faGavel} /> Terms & Conditions</>}>
                            <TermsAndConditions />
                        </Tab>
                        <Tab eventKey="privacy" title={<><FontAwesomeIcon icon={faLock} /> Privacy</>}>
                            <PrivacyPolicy />
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='primary' onClick={()=>{setShow(false)}}>Done</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
});

export default LegalModal;