import { faGavel, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Tab, Tabs } from 'react-bootstrap';
import PrivacyPolicy from '../components/legal/PrivacyPolicy';
import TermsAndConditions from '../components/legal/TermsAndConditions';
import { IUser } from '../types/user';

const Legal = ( props:{user:IUser|null} ) => {
  return (<>
    <Container style={{textAlign: 'center'}}>
      <br/>
    <Tabs className={'mb-3'} defaultActiveKey={'terms&conditions'} id={'legalTabs'}>
                        <Tab eventKey="terms&conditions" title={<><FontAwesomeIcon icon={faGavel} /> Terms & Conditions</>}>
                            <TermsAndConditions />
                        </Tab>
                        <Tab eventKey="privacy" title={<><FontAwesomeIcon icon={faLock} /> Privacy</>}>
                            <PrivacyPolicy />
                        </Tab>
                    </Tabs>
    </Container>
  </>);
}

export default Legal;