import { faSignOutAlt, faEdit, faGlobeAmericas, faBinoculars, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './usernav.css';
import { Dropdown } from 'react-bootstrap';
import { IUser } from '../../types/user';

const UserNav = ( props:{ user:IUser|null }) => {
  function navigateToAdminTools(){
      window.location.replace(window.location.origin+'/admintools');
  }

  
  if( props.user !== null ){
    return(
        <>
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="user-dropdown" className="userNavbarDropdownButton">
                    <img className="userNavbarPicture" src={'/profilepics/'+props.user.picture+"?t="+ new Date().getTime()} onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src="/NoUser_512.png";
                        }} alt="profile"/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as={Link} to={'user'}>
                          <strong>{props.user.name}</strong><br />
                          @{props.user.username}
                    </Dropdown.Item>
                    {props.user?.access.includes('admin')?<>
                      <Dropdown.Divider />
                      <Dropdown.Item as={Link} to={'/'} onClick={()=>{navigateToAdminTools()}} target="_blank"><FontAwesomeIcon icon={faHeadset} /> Admin</Dropdown.Item>
                    </>:<></>}
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} to={'/logout'}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
      )
    }
    else{
      return <>Error: User is Null!</>;
    }
}

export default UserNav;