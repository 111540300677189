import { Link } from 'react-router-dom';
import { IUser } from '../types/user';
import UserNav from './user/usernav';

const Nav = ( props:{ user:IUser|null, onChange?:Function|null }) => {
    let menu;

    // if a user is set, the user is logged in. Conditionally render
    // the navbar content
    if(props.user === null){
        menu = (
            <ul className="navbar-nav ms-auto mb-2 mb-md-0 navbar-right">
                {/*<li className="nav-item">
                    <Link to="/register" className="nav-link">Register</Link>
                </li>*/}
                <li className="nav-item">
                    <Link to="/login" className="nav-link">Log in</Link>
                </li>
            </ul>
        );
    }
    else {
        menu = (
            <ul className="navbar-nav ms-auto mb-2 mb-md-0 navbar-right">
                <li className='nav-item'>
                    <UserNav user={props.user}></UserNav>
                </li>
            </ul>
        );
    }

    return(
        <nav className="navbar navbar-expand navbar-dark bg-dark" style={{zIndex: "1", boxShadow: "0 2px 4px 0 rgba(0,0,0,.2)"}}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img alt="Freya Logo" src={process.env.PUBLIC_URL+"/logo512.png"} height="40" className="d-inline-block align-top" />
                </Link>
                <ul className="nav m-auto">
                    <li className="nav navbar-nav">
                        <a className="nav-link" href="/freya">Freya</a>
                    </li>
                    <li className="nav navbar-nav">
                        <a className="nav-link" href="/biosphere/">Biosphere</a>
                    </li>
                </ul>
                {menu}
            </div>
        </nav>
    )
}

export default Nav;