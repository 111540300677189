import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";

const PrivacyPolicy = () => {
  return(<>
    <h2>Privacy Policy</h2>
    <p>How we're handling sensitive data.</p>

    <Alert variant='info'><FontAwesomeIcon icon={faInfoCircle}/> <strong>Biosphere is still in development. </strong> We're working
    on a decent privacy policy. But I can assure you, Biosphere is designed with the user's best interests in mind.</Alert>

    <h3>Your user data</h3>
    <p>
      Biosphere is a platform designed for sharing. A lot of information will be shared on the platform. Though, no other users
      are able to see your e-mail address or your password. Your password is encrypted and stored with decent technology. Nobody,
      not even the developer(s) of Biosphere, have any means to get your unencrypted password. Your user data will never be used outside
      of the boundaries of Biosphere.
    </p>
    <h3>Account lifecycle</h3>
    <p>
      At all times, you can access, update and delete your data. After deletion, whether by your choise or automatically by our
      activity checker, non of your data will persist on our server. Your account is automatically deleted after 3 years of inactivity.
    </p>
  </>);
}

export default PrivacyPolicy;