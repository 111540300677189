import { IUser } from "../types/user";

const NotFound = ( props:{user:IUser|null} ) => {

    return (<>
            <main className="form-signin" style={{textAlign: 'center'}}>
                <img alt="logo" src={process.env.PUBLIC_URL+'/logo512.png'} width="100%" />
                <br />
                <br />
                <h1>404 Not Found</h1>
                <p>The page you are looking for was not found.</p>
            </main>
        </>);
  }
  
  export default NotFound;