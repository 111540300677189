import {  useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { api_user_activate, api_user_logout } from '../api/user';
import LoaderOverlay from '../components/LoadingOverlay';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { IUser } from '../types/user';

const User = ( props:{user:IUser|null, onChange:Function|null } )=>{
  // Loading Overlay
  const[ loading, setLoading] = useState(true);
  const[ loadingText, setLoadingText ] = useState('Activating account');
  const[ loadingSubtext, setLoadingSubtext ] = useState('Just a sec...');
  // Error message
  const[ errMsg, setErrMsg ] = useState('');
  // Navigate
  const navigate = useNavigate();
  // URL parameters
  const { email, code} = useParams();
  // URL Query
  const [searchParams, setSearchParams] = useSearchParams();
  const app = searchParams.get('app');

  // If a user is already logged in, log out
  if(props.user){
    api_user_logout();
  }

  useEffect(()=>{
    activateAccount();
  },[email, code]);

  // Delete this user
  async function activateAccount(){
    if(!email || !code) return;
    setLoadingText('Activating account...');
    setLoadingSubtext('Just a sec...');
    setLoading(true);
    const result = await api_user_activate( email, code );
    if( result.message !== 'success' ){
      setLoading(false);
      setErrMsg(result.message);
      return;
    }
    setLoadingText('Account activated!');
    setLoadingSubtext('You can now log in...')
    setTimeout(()=>{
      setLoading(false);
      if(app){
        navigateToApp(app);
        return;
      }
      navigate('/login');
    },1500);
  }


  function navigateToApp( app:string ){
    window.location.replace(window.location.origin+'/'+app+'/login');
}

  return(
    <>
      <LoaderOverlay spinner hidden={!loading} text={loadingText} subtext={loadingSubtext}/>
      <Container>
        <br/>
        {errMsg!==''?<Alert variant='danger'><strong><FontAwesomeIcon icon={faWarning}/> Error: </strong>{errMsg}</Alert>:<></>}
      </Container>
    </>
  )
}

export default User;